<template>
	<div class="login">
	<el-card style="width: 500px;">
		<template #header>
			<div class="title">{{brand}} Management</div>
		</template>
		<el-form ref="loginForm" :model="login">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item prop="email" :rules="[{required: true, validator: emailValidator, trigger: 'blur'}]">
				<el-input v-model="login.email" placeholder="Email" clearable></el-input>
			</el-form-item>
			<el-form-item prop="password" :rules="[{required: true, validator: passwordValidator, trigger: 'blur'}]">
				<el-input v-model="login.password" placeholder="Password" clearable show-password></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" style="width: 100%;" :loading="loading" @click="doLogin">Login</el-button>
			</el-form-item>
		</el-form>
	</el-card>
	<div v-if="version" class="version">
		- {{version}} -
	</div>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import config from '../../package.json'
import authAPI from '../api/auth'
import { Actions, useStore } from '../store'
import { StaffAuthed, StaffEmailPasswordLogin } from '../types/auth'
import { Result } from '../types/common/result'
import { emailValidator, passwordValidator } from '../validators'

const brand = import.meta.env.VITE_APP_BRAND
const version = computed(() => config.version)

const router = useRouter()
const store = useStore()

const loading = ref<boolean>(false)
const message = ref<string>('')

const login = reactive<StaffEmailPasswordLogin>(new StaffEmailPasswordLogin())
const loginForm = ref<InstanceType<typeof ElForm>>()
const doLogin = function () {
	loginForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			authAPI.emailPasswordLogin(login).then((response: AxiosResponse<Result<StaffAuthed>>) => {
				const result = response.data
				if (result.success) {
					const staffAuthed = result.data
					const staff = staffAuthed.staff
					if (staff.locked) {
						message.value = 'Account is locked'
					} else {
						store.dispatch(Actions.LOGIN, staffAuthed).then(() => {
							router.push({
								name: 'welcome'
							})
						})
					}
				} else {
					ElMessage.error(result.message)
				}
			}).catch((error: AxiosError) => {
				const response = error.response
				if (response) {
					if (response.status === 400) {
						message.value = 'Wrong password'
					} else {
						message.value = error.message
					}
				} else {
					ElMessage.error(error.message)
				}
			}).finally(() => {
				loading.value = false
			})
		} else {
			return false
		}
	})
}
</script>
<style scoped>
.login {
	display: flex; 
	flex-direction: column; 
	align-items: center; 
	justify-content: center; 
	min-height: 100vh; 
	background-color: var(--el-color-primary);
}

.title {
	line-height: 40px; 
	font-size: 20px; 
	text-align: center;
}

.version {
	width: 100%; 
	text-align: center; 
	font-size: 10px; 
	color: gainsboro; 
	margin-top: 20px;
}
</style>